<template>
  <div id="corpo-documento">
    <table
      id="righe-documento"
      @click="mode.edit || mode.readOnly ? null : toggleEdit()"
      :class="mode.edit || mode.readOnly ? undefined : 'clickToEdit'"
      v-tooltip="tooltip()"
    >
      <HeaderRigheDettaglioDocumentoSpSa />
      <tbody ref="corpoTabella" v-if="!isTabellaVuota">
        <RigaDettaglioDocumentoSpSa
          v-for="r in righe"
          :key="_buildRK(r)"
          :riga="r"
          :ref="`riga-${r._id}`"
        />
      </tbody>
      <FooterRigheDettaglioDocumentoSpSa />
    </table>
    <div v-show="bollo.imposta > 0" id="msgBolloSTS">{{ msgBolloSTS }}</div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { R } from "mida4-web-app-utils";
import { CassaPrevidenziale } from "mida4-fattura-rapida-spese-sanitarie";
import HeaderRigheDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/TabellaRigheDettaglioDocumentoSpSa/HeaderRigheDettaglioDocumentoSpSa";
import RigaDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/TabellaRigheDettaglioDocumentoSpSa/RigaDettaglioDocumentoSpSa";
import FooterRigheDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/TabellaRigheDettaglioDocumentoSpSa/FooterRigheDettaglioDocumentoSpSa";
import { mapGetters, mapActions } from "vuex";

const NAME = "TabellaRigheDettaglioDocumentoSpSa";

export default {
  name: NAME,
  components: {
    HeaderRigheDettaglioDocumentoSpSa,
    RigaDettaglioDocumentoSpSa,
    FooterRigheDettaglioDocumentoSpSa,
  },
  data() {
    return {
      isTabellaVuota: false,
      msgBolloSTS: this.DEFAULTS.MSG_BOLLO_STS,
    };
  },
  computed: {
    ...mapGetters({
      storedUserDefaults: "user/getDefaults",
      defaultCassaVal: "user/getDefaultCassaVal",
      mode: "elaboranda/tabellaMode",
      isModificabile: "fatture/isAttivoModificabile",
      getAddebitoBollo: "elaboranda/addebitoBollo",
      getImpostaBollo: "elaboranda/impostaBollo",
      showCliente: "elaboranda/showCliente",
      campiRiga: "elaboranda/getCampiRiga",
      generaNuovaRiga: "elaboranda/generaNuovaRiga",
      applicaRitenuta: "elaboranda/applicaRitenuta",
      getRighe: "elaboranda/righe",
      rigaModificanda: "elaboranda/rigaModificanda",
      statoRigaModificanda: "elaboranda/statoRigaModificanda",
      hasCampiMancanti: "elaboranda/hasCampiMancanti",
    }),
    righe: {
      get() {
        return this.getRighe;
      },
      set(righe) {
        this.setRighe(righe);
      },
    },
    bollo() {
      return {
        imposta: {
          get() {
            return this.getImpostaBollo;
          },
          set(i) {
            return this.setImpostaBollo(i);
          },
        },
        addebito: {
          get() {
            return this.getAddebitoBollo;
          },
          set(a) {
            return this.setAddebitoBollo(a);
          },
        },
      };
    },
    lblCassa() {
      return this.storedUserDefaults.tipoCassaPrevidenziale
        ? CassaPrevidenziale.enti[
            this.storedUserDefaults.tipoCassaPrevidenziale
          ].abbreviazione
        : null;
    },
  },
  created() {
    // if (this.righe.length === 0 && this.mode.readOnly === false) {
    //   this.aggiungiRiga();
    // }
    this.$nextTick(() => {
      if (this.righe.length === 1) {
        this.persistRiga(this.righe[0]._id);
      }
    });
  },
  methods: {
    ...mapActions({
      setRighe: "elaboranda/setRighe",
      setImpostaBollo: "elaboranda/setImpostaBollo",
      setAddebitoBollo: "elaboranda/setAddebitoBollo",
      persistRiga: "elaboranda/persistRiga",
      toggleEdit: "elaboranda/toggleEditCliente",
      setCaretPos: "elaboranda/setCaretPos",
      aggiornaCassaPrevidenzialeERitenuta:
        "elaboranda/aggiornaCassaPrevidenzialeERitenuta",
      setHascampiMancanti: "elaboranda/setHasCampiMancanti",
      resetHasCampiMancanti: "elaboranda/resetHasCampiMancanti",
      aggiungiRiga: "elaboranda/aggiungiRiga",
      editRiga: "elaboranda/toggleEditRiga",
      eliminaRiga: "elaboranda/eliminaRiga",
    }),
    _buildRK(r) {
      return R.isNil(r) ? "" : btoa(`${r._id}_${r.editing}_${r.IVA}`);
    },
    tooltip() {
      return this.mode.readOnly
        ? undefined
        : {
            content:
              this.mode.edit === true
                ? "Tutti i campi delle righe devono essere valorizzati"
                : "Click per modificare",
            classes: this.mode.edit === true ? "error-tooltip" : undefined,
            trigger: this.mode.edit === true ? "manual" : "hover",
            show: this.hasCampiMancanti === true,
          };
    },
    evidenziaCampiMancanti(rID, campiMancanti) {
      // this.hasCampiMancanti = true
      document
        .querySelector(`#riga-${rID}`)
        .querySelectorAll("td")
        .forEach((el) => {
          if (campiMancanti.includes(el.className)) {
            el.querySelector("input").classList.add("input-error");
          }
        });
    },
    rimuoviEvidenzaCampiMancanti() {
      document
        .querySelectorAll("input")
        .forEach((el) => el.classList.remove("input-error"));
      // this.hasCampiMancanti = false
    },
  },
  watch: {
    hasCampiMancanti(val) {
      if (val) {
        this.evidenziaCampiMancanti();
      } else {
        this.rimuoviEvidenzaCampiMancanti();
      }
    },
  },
};
</script>

<style src="@/styles/custom/components/dettaglio_documento/righe/_tabella_righe.scss" lang="scss"></style>