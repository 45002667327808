<template>  
  <thead>
    <tr>
      <th class="descrizione">Descrizione</th>
      <th class="tipo-spesa">Tipo spesa</th>
      <th class="importo-unitario" v-tooltip="tooltip.importo">Imp. Unit.</th>
      <th class="quantita" v-tooltip="tooltip.quantita">Qta</th>
      <th class="IVA">IVA</th>
      <th v-if="showCassa" class="cassa-previdenziale">Cassa prev.</th>
      <th class="importo-totale" :colspan="mode.edit ? undefined : 3">Importo</th>
      <th class="action" v-if="mode.edit"></th>
      <th class="action" v-if="mode.edit"></th>
    </tr>
  </thead>
</template>

<script>
import { mapGetters } from 'vuex'

const NAME = 'HeaderRigheDettaglioDocumentoSpSa'

export default {
  name: NAME,
  computed: {
    ...mapGetters({
      mode:       'elaboranda/tabellaMode',
      showCassa:  'elaboranda/showCassa'
    }),
    tooltip(){
      return {
        importo: this.mode.readOnly ? undefined : {content: 'Importo Unitario', trigger:this.mode.edit === false ? 'manual' : 'hover'},
        quantita: this.mode.readOnly ? undefined : {content: 'Quantità', trigger:this.mode.edit === false ? 'manual' : 'hover'}
      }
    }
  }
}
</script>