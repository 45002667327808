<template>
  <tfoot>
    <tr>
      <th class="descrizione" colspan="5">
        <div v-if="impostaBollo > 0">
          <span id="hasBollo"
            >Soggetta a imposta di bollo di €
            {{
              impostaBollo.toLocaleString("it", { minimumFractionDigits: 2 })
            }}</span
          >
          <button
            v-if="!getAddebitoBollo && !viewMode"
            id="addebitoBollo"
            class="accent-button"
            @click="setAddebitoBollo(true)"
          >
            Addebito al cliente
          </button>
        </div>
      </th>
      <th class="importo-totale" :colspan="colSpanTotaleDaPagare">
        Totale
        <div id="totali">
          € {{ getImportoTotale() }}
          <span class="IVA">di cui IVA € {{ getIVATotale() }}</span>
        </div>
      </th>
      <!-- <th colspan=2 v-if="! viewMode"></th> -->
    </tr>
  </tfoot>
</template>

<script>
const NAME = "FooterRigheDettaglioDocumentoSpSa";
import { mapGetters, mapActions } from "vuex";
import {
  asString,
  getImportoTotale,
  getIVATotale,
} from "mida4-fattura-rapida-spese-sanitarie/doc";
export default {
  name: NAME,
  computed: {
    ...mapGetters({
      mode: "elaboranda/tabellaMode",
      getAddebitoBollo: "elaboranda/addebitoBollo",
      impostaBollo: "elaboranda/impostaBollo",
      showCassa: "elaboranda/showCassa",
      righe: "elaboranda/righe",
    }),
    colSpanTotaleDaPagare() {
      return (this.showCassa ? 1 : 0) + 1;
    },
    viewMode() {
      return !this.mode.edit;
    },
  },
  methods: {
    ...mapActions({
      setAddebitoBollo: "elaboranda/setAddebitoBollo",
    }),
    getImportoTotale() {
      return asString(getImportoTotale(this.righe));
    },
    getIVATotale() {
      return asString(getIVATotale(this.righe));
    },
  },
};
</script>