<template>
  <div id="azioni-dettaglio-documento">
    <button v-if="mode.modificabile" id="invia-doc" @click="invia" :class="mode.inviabile ? 'attivo' : undefined">
      <font-awesome-icon class="azioni-icon" icon="file-upload"/>
      Invia
    </button>
    <button v-if="mode.eliminabile" id="elimina-doc" @click="elimina" class="attivo" >
      <font-awesome-icon class="azioni-icon" icon="times"/>
      Elimina
    </button>
    <div style="clear:both;"></div>
  </div>
</template>

<script>

const NAME = 'AzioniDettaglioDocumentoSpSa'

export default {
  name: NAME,
  props: ['mode'],
  methods: {
    invia(){
      if(this.mode.inviabile){
        this.$emit('inviaDoc')
      }
    },
    elimina(){
      this.$emit('eliminaDoc')
    }
  }
}
</script>

<style src="@/styles/custom/components/dettaglio_documento/_azioni.scss" lang="scss"></style>