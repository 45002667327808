<template>
  <div
    v-if="elencoMessaggi.length > 0"
    :class="['messaggi_documento', tipo !== undefined ? 'single' : 'multi']"
  >
    <h4 v-if="tipo !== undefined">
      {{ tipo === "segnalazioni" ? "Segnalazioni:" : "Errori:" }}
    </h4>
    <ul class="dettaglio_messaggi">
      <li v-for="m in elencoMessaggi" :key="m">{{ m }}</li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import { R } from "mida4-web-app-utils";

const NAME = "MessaggiDocumentoSpSa";

const MessaggiDocumentoSpSa = {
  name: NAME,
  props: ["tipo", "messaggi"],
  computed: {
    elencoMessaggi() {
      return this.messaggi === null
        ? []
        : this.messaggi
            .split("\n")
            .map((m) => m.toLowerCase())
            .filter(
              (m) =>
                R.isNil(this.tipo) || m !== "operazione eseguita correttamente"
            );
    },
  },
};
export function generaElencoMessaggi(props) {
  const MessaggiClass = Vue.extend(MessaggiDocumentoSpSa);
  const instance = new MessaggiClass({ propsData: props });

  instance.$mount();
  return instance.$el;
}
export default MessaggiDocumentoSpSa;
</script>

<style src="@/styles/custom/components/dettaglio_documento/_messaggi.scss" lang="scss"></style>