<template>
  <tr :id="`riga-${riga._id}`" :class="isEdit === true ? 'editing' : null">
    <CampoRigaDocumentoSpSa
      v-for="campo in campi"
      :editing="isEdit && riga._id !== 'bollo'"
      :key="`${riga._id}_${campo}`"
      :nomeCampo="campo"
      :idRiga="riga._id"
      :tipoRiga="riga.tipoRiga"
      :valore="valoreCampo(campo)"
      :opts="editOpts[campo]"
    />
    <td class="importo-totale ignore">
      <span v-if="riga.importoUnitario !== null && riga.quantita !== null">{{
        importoTotale
      }}</span>
    </td>
    <td class="action aggiungi ignore" v-if="!viewMode">
      <font-awesome-icon
        icon="plus"
        :class="
          REGEXES.CASSA_PREVIDENZIALE.test(riga._id)
            ? 'cassaPrevidenziale'
            : riga._id
        "
        @click="aggiungiRiga(riga._id)"
        @keyup="parseKeyup"
        v-tooltip="
          !REGEXES.BOLLO_O_CASSA.test(riga._id) ? 'aggiungi riga' : undefined
        "
      />
    </td>
    <td class="action elimina ignore" v-if="!viewMode">
      <font-awesome-icon
        icon="trash"
        @click="eliminaRiga(riga._id)"
        @keyup="parseKeyup"
        v-tooltip="riga._id === 'bollo' ? 'Annulla addebito bollo' : 'elimina'"
      />
    </td>
  </tr>
</template>

<script>
import { R, REGEXES } from "mida4-web-app-utils";
import { ValoriIVA } from "mida4-fattura-rapida-spese-sanitarie";
import { arrotonda } from "mida4-web-app-utils/functions";
import { mapGetters, mapActions } from "vuex";
// import SchemaRigaFattura from '@/entities/schemas/riga-fattura'
import CampoRigaDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/TabellaRigheDettaglioDocumentoSpSa/CampoRigaDocumentoSpSa";

import Invianti from "@/entities/enums/invianti";
import TipiSpesa from "@/entities/enums/tipi-spesa";

export default {
  props: ["riga"],
  components: {
    CampoRigaDocumentoSpSa,
  },
  data() {
    return {
      REGEXES: REGEXES,
      editOpts: {
        descrizione: { type: "text" },
        importoUnitario: {
          type: "text",
          inputmode: "numeric",
          pattern: "^\\d+(,|\\.)?\\d{0,2}$",
          min: 0,
        },
        quantita: {
          type: "text",
          inputmode: "numeric",
          pattern: "^\\d+(,|\\.)?\\d{0,2}$",
          min: 1,
        },
        tipoSpesa: { selectOptions: null },
        cassaPrevidenziale: {
          type: "checkbox",
          disabled: this.riga.IVA === "N1",
        },
        IVA: {
          selectOptions: ValoriIVA.getEntries(this.DEFAULTS.DESTINAZIONE),
          invertSelectLabel: true,
        },
      },
      isEdit: this.riga.editing && !(this.viewMode || this.readOnly),
    };
  },
  mounted() {
    this.editOpts.tipoSpesa.selectOptions = TipiSpesa.fromBitMask(
      Invianti[this.user.tipoSoggetto]
    );
  },
  computed: {
    ...mapGetters({
      user: "user/getData",
      storedUserDefaults: "user/getDefaults",
      campi: "elaboranda/getCampiRiga",
      mode: "elaboranda/tabellaMode",
    }),
    viewMode() {
      return !this.mode.edit;
    },
    readOnly() {
      return this.mode.readOnly;
    },
    importoTotale() {
      return R.isNil(this.riga.importoUnitario)
        ? null
        : `€ ${arrotonda(
            parseFloat(this.riga.importoUnitario * this.riga.quantita)
          ).toLocaleString("it", { minimumFractionDigits: 2 })}`;
    },
    // getCampiRiga() {
    //   let campiEsclusi = []
    //   if(! Log.debug('riga this.showCassa', this.showCassa)){
    //     campiEsclusi.push('cassaPrevidenziale')
    //   }
    //   return campiRiga({SchemaRigaFattura, campiEsclusi})
    // }
  },
  methods: {
    ...mapActions({
      aggiungiRiga: "elaboranda/aggiungiRiga",
      eliminaRiga: "elaboranda/eliminaRiga",
    }),
    valoreCampo(campo) {
      let val = this.riga[campo];
      if (R.isNil(val)) {
        // se undefined diventa false su cassa prev, null negli altri casi
        val = campo === "cassaPrevidenziale" ? false : null;
      }
      return val;
    },
    parseKeyup(e) {
      const td = e.target.closest("td");
      if (["Enter", "ArrowRight", " "].includes(e.key)) {
        if (Array.from(td.classList).includes("aggiungi")) {
          this.aggiungiRiga(this.riga._id);
        } else if (Array.from(td.classList).includes("elimina")) {
          this.eliminaRiga(this.riga._id);
        }
      }
    },
  },
};
</script>

<style src="@/styles/custom/components/dettaglio_documento/righe/_riga.scss" lang="scss"></style>