import { render, staticRenderFns } from "./CampoRigaDocumentoSpSa.vue?vue&type=template&id=e64c8324&scoped=true"
import script from "./CampoRigaDocumentoSpSa.vue?vue&type=script&lang=js"
export * from "./CampoRigaDocumentoSpSa.vue?vue&type=script&lang=js"
import style0 from "@/styles/custom/components/dettaglio_documento/righe/_campo_riga.scss?vue&type=style&index=0&id=e64c8324&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e64c8324",
  null
  
)

export default component.exports