<template>
  <div id="progress">
    <h3>Mantenere aperta questa finestra fino al termine dell'operazione</h3>
    <ul class="multi-steps">
      <li :class="fase !== null && fase < FasiInvio.AGGIUNGI_KO ? 'is-active' : undefined">
        <span :class="fase !== null ? 'label' : undefined">Valida</span>
      </li>
      <li :class="fase !== null && fase > FasiInvio.AGGIUNGI_KO ? 'is-active' : undefined">
        <span :class="fase !== null && fase > FasiInvio.AGGIUNGI_KO ? 'label' : undefined">{{stepLabel('MIDA', fase)}} Mida4</span>
      </li>
      <li :class="fase !== null && fase >=  FasiInvio.INVIA_OK ? 'is-active' : undefined">
        <span :class="fase !== null && fase >=  FasiInvio.INVIA_OK ? 'label' : undefined">{{stepLabel('STS', fase)}} Servizio TS</span>
      </li>
    </ul>
  </div>  
</template>

<script>
import FasiInvio from '@/entities/enums/fasi-invio'

const NAME = 'ProgressInvioDocumentoSpSa'

export default {
  name: NAME,
  props: ['fase', 'servizio'],
  data() {
    return {
      FasiInvio: FasiInvio
    }
  },
  methods: {
    stepLabel(step, fase) {
      
      const INVIO       = 'Invio a'
      const ERRORE      = 'Errore invio a'
      const REGISTRATA  = 'Registrata da'
      const ACCOLTA     = 'Accolta da'

      switch(fase) {
        case FasiInvio.AGGIUNGI:
          return INVIO
        case FasiInvio.AGGIUNGI_KO:
          return step === 'MIDA' ? ERRORE : INVIO
        case FasiInvio.INVIA:
          return step === 'MIDA' ? REGISTRATA : INVIO
        case FasiInvio.INVIA_KO:
          return step === 'MIDA' ? REGISTRATA : ERRORE
        case FasiInvio.INVIA_OK:
          return step === 'MIDA' ? REGISTRATA : ACCOLTA
      }
    }
  }
}
</script>

<style src="@/styles/custom/components/dettaglio_documento/_progress_invio.scss" lang="scss"></style>