<template>
  <td
    :ref="`${idRiga}_${nomeCampo}`"
    :data-hasDefault="attrs.value !== undefined"
    :data-val="attrs.type === 'checkbox' ? valore.toString() : valore"
    :data-campo="nomeCampo"
    :class="tdClassName"
    @mouseup="mode.readOnly ? null : editRiga(idRiga)"
  >
    <span
      v-show="showSpan"
      v-tooltip="{ content: selectedTooltip, trigger: 'hover' }"
      >{{ valoreTxt }}</span
    >
    <input
      v-if="hasInput"
      v-show="showInput"
      autocomplete="off"
      :id="idCampo"
      :ref="`${idRiga}_${nomeCampo}_input`"
      :name="nomeCampo"
      :value="computedValue"
      :type="attrs.type"
      :inputmode="attrs.inputmode"
      :pattern="attrs.pattern"
      :disabled="checkDisabled"
      :checked="isChecked"
      lang="it-150"
      @input="persistValue"
    />
    <select
      v-else
      v-show="isEdit"
      :id="idCampo"
      :ref="`${idRiga}_${nomeCampo}_select`"
      :name="nomeCampo"
      @input="persistValue"
    >
      <option
        v-for="[k, v] in opts.selectOptions"
        :key="k"
        :value="k"
        :selected="valore === k"
      >
        {{ getSelectLabel(k, v) }}
      </option>
    </select>
    <!-- l'overlay permette di intercettare il click quando l'elemento ha l'attributo disabled -->
    <div
      v-if="
        nomeCampo === 'cassaPrevidenziale' &&
        checkDisabled &&
        !isAutoGenerata &&
        !mode.readOnly
      "
      @click="editRiga(idRiga)"
      class="disabled-check-click-overlay"
    />
  </td>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { R, Forms, REGEXES } from "mida4-web-app-utils";
import { ValoriIVA } from "mida4-fattura-rapida-spese-sanitarie";
import {
  asString,
  isRigaAutogenerata,
} from "mida4-fattura-rapida-spese-sanitarie/doc";
import { kebabCase, formatImporto } from "mida4-web-app-utils/functions";
import TipiSpesa from "@/entities/enums/tipi-spesa";

const NAME = "CampoRigaDocumentoSpSa";

export default {
  props: ["idRiga", "tipoRiga", "editing", "nomeCampo", "valore", "opts"],
  data() {
    return {
      NAME,
      attrs: this.opts,
      isEdit: this.editing === true,
      refPrefix: `${this.idRiga}_${this.nomeCampo}`,
      inputEl: null,
      selectedTooltip: null,
      idCampo: `id_${this.idRiga}_${this.nomeCampo}`,
    };
  },
  mounted() {
    this.inputEl =
      this.$refs[`${this.refPrefix}_input`] ||
      this.$refs[`${this.refPrefix}_select`];
    if (this.editing) {
      if (
        R.isNil(this.caret.id) ||
        (R.isEmpty(this.caret.id) && this.idCampo === "descrizione")
      ) {
        this.$nextTick(() => this.inputEl.focus());
      } else if (
        this.focused === this.idCampo &&
        ["descrizione", "importoUnitario", "quantita"].includes(this.nomeCampo)
      ) {
        this.$nextTick(() => {
          // this.$log.debug('caret', JSON.stringify(this.caret))
          Forms.restoreCaretPosition(this.caret);
        });
      }
    } else {
      const hasToolTip =
        this.inputEl.tagName.toUpperCase() === "SELECT" &&
        !R.isNil(this.valore) &&
        isNaN(this.valore) &&
        this.valore !== "NORMALE";

      if (hasToolTip) {
        const allEntries = [
          ...TipiSpesa.fromBitMask(TipiSpesa.ALL),
          ...ValoriIVA.getEntries(this.DEFAULTS.DESTINAZIONE),
        ];
        this.$nextTick(() => {
          const TOOLTIP = 1;
          this.selectedTooltip = allEntries.find(([k]) => k === this.valore)[
            TOOLTIP
          ];
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      caret: "elaboranda/caret",
      mode: "elaboranda/tabellaMode",
    }),
    valoreTxt() {
      let val = asString(parseFloat(this.valore));
      switch (this.nomeCampo) {
        case "importoUnitario":
          return val === "NaN" ? "" : `€ ${val}`;
        case "quantita":
          return val === "NaN" ? "" : val;
        case "IVA":
          return isNaN(this.valore)
            ? this.valore
            : `${this.valore.toLocaleString("it", {
                maximumFractionDigits: 2,
              })}%`;
        case "cassaPrevidenziale":
          return REGEXES.BOLLO_O_CASSA.test(this.idRiga) ? "" : this.valore;
        default:
          return this.valore;
      }
    },
    computedValue() {
      let ret;
      if (this.attrs.inputmode === "numeric") {
        ret = formatImporto({ num: Number(this.valore), decimaliSempre: false })
          .replace(REGEXES.DECIMALI_A_ZERO, "")
          .replaceAll(".", "");
      } else ret = this.valore;
      // if(this.nomeCampo === 'importoUnitario') this.$log.debug('computedValue', ret)
      return ret;
    },
    tdClassName() {
      const nomeCampoEsploso = this.nomeCampo.replace(REGEXES.MAIUSCOLE, " $1");
      return this.nomeCampo === "IVA"
        ? this.nomeCampo
        : kebabCase(nomeCampoEsploso);
    },
    checkDisabled() {
      return (
        this.attrs.type === "checkbox" && (!this.isEdit || this.attrs.disabled)
      );
    },
    hasInput() {
      return R.isNil(this.opts.selectOptions);
    },
    isAutoGenerata() {
      return isRigaAutogenerata(this.idRiga);
    },
    isChecked() {
      return this.attrs.type === "checkbox" && this.valore === true
        ? "checked"
        : undefined;
    },
    showSpan() {
      return this.attrs.type !== "checkbox" && !this.isEdit;
    },
    showInput() {
      return (
        (this.isEdit || this.attrs.type === "checkbox") && !this.isAutoGenerata
      );
    },
    applyClickOverlay() {
      return (
        this.nomeCampo === "cassaPrevidenziale" &&
        this.checkDisabled &&
        !this.isAutoGenerata
      );
    },
  },
  methods: {
    ...mapMutations({
      setCaretPos: "elaboranda/setCaretPos",
    }),
    ...mapActions({
      persistRiga: "elaboranda/persistRiga",
      editRiga: "elaboranda/toggleEditRiga",
    }),
    keepFocusOnMe(evt) {
      this.setCaretPos({ id: evt.target.id, pos: evt.target.selectionStart });
    },
    /**
     * chiamato ad ogni input
     * persiste il valore impostato per poter fare i calcoli relativi alle righe autogenerate
     * ed emette un evento che gestisce il ritorno del focus e del cursore alla posizione
     * corrente
     */
    persistValue(evt) {
      const isCassa = this.nomeCampo === "cassaPrevidenziale";
      const isNumeric = this.attrs.inputmode === "numeric";

      const oldStringVal =
        this.$refs[`${this.idRiga}_${this.nomeCampo}`].dataset.val;
      const oldVal = parseFloat(oldStringVal ?? 0);

      let newVal = this.inputEl.value;

      // se il campo è cassa verifico lo stato del checkbox
      if (isCassa) {
        newVal = this.inputEl.checked;
      }
      // gestione input specifica per i campi numerici
      else if (isNumeric) {
        const rx = new RegExp(evt.target.attributes.pattern.value);
        if (newVal.length > 0 && !rx.test(newVal)) {
          this.inputEl.value = this.computedValue;
          return false;
        }

        // l'utente cerca di inserire una cifra decimale dopo la seconda
        // viene escluso dal controllo il tasto back
        // viene esclusa la situazione in cui ci sia una selezione di testo attiva e
        // l'evento input generi una stringa più corta di quella presente

        const dueDecimaliPresenti = (oldVal * 10) % 1 > 0;
        const lunghezzaParteDecimale = isNaN(this.inputEl.value.split(",")[1])
          ? 0
          : this.inputEl.value.split(",")[1]?.length ?? 0;
        const inserisceUltimoCarattere =
          evt.target.selectionStart === newVal.length;
        const nonHaSelezionato = newVal.length > (oldStringVal?.length ?? 0);
        const premutoTastoBack = evt.inputType === "deleteContentBackward";

        if (
          dueDecimaliPresenti &&
          inserisceUltimoCarattere &&
          nonHaSelezionato &&
          !premutoTastoBack
        ) {
          this.inputEl.value = this.computedValue;
          return false;
        }

        if (!R.isEmpty(this.inputEl.value)) {
          // evitiamo di inserire due virgole (o un punto dopo la virgola)
          if (lunghezzaParteDecimale === 0 && [",", "."].includes(evt.data)) {
            const v = this.inputEl.value.replace(".", ",");
            // this.$log.debug(v)
            this.inputEl.value = `${v.split(",")[0]},`;
            return false;
          }
          // l'utente ha digitato uno zero subito dopo la virgola
          if (lunghezzaParteDecimale === 1 && evt.data === "0") {
            return false;
          }
          // se la stringa risultante dall'input ha più di tre decimali la tronco a due dec
          else if (lunghezzaParteDecimale > 2) {
            this.inputEl.value = `${
              this.inputEl.value.split(",")[0]
            },${this.inputEl.value.split(",")[1].substring(0, 2)}`;
          }
        }

        // l'utente ha digitato il punto o la virgola
        if ([",", "."].includes(evt.data)) {
          if (oldStringVal.includes(",")) {
            this.inputEl.value = oldStringVal;
            return false;
          }
          // se è già un numero decimale l'evento viene annullato
          if (oldVal % 1 > 0) {
            this.inputEl.value = this.computedValue;
          }
          // se ha digitato un punto viene trasformato in virgola
          else if (evt.data === ".") {
            this.inputEl.value = newVal.replace(".", ",");
          }

          // se l'ultimo carattere è una virgola o il valore non è cambiato
          if (
            this.inputEl.value[this.inputEl.value.length - 1] === "," ||
            this.inputEl.value.replace(",", ".") === oldVal
          ) {
            // esco dalla funzione: non serve persistere il valore
            return false;
          }
        }
        // se il valore visualizzato precedentemente era zero
        // la casella viene valorizzata al tasto premuto
        if (oldVal === 0) {
          this.inputEl.value = evt.data;
        }
        // se esiste un attributo minimo lo imposto
        const minVal = R.isNil(this.attrs.min) ? 0 : parseFloat(this.attrs.min);

        // assegno il minimo se nullo
        newVal = R.isEmpty(this.inputEl.value)
          ? minVal
          : parseFloat(this.inputEl.value.replace(",", "."));
      }

      // imposto data-val
      this.$refs[`${this.idRiga}_${this.nomeCampo}`].dataset.val = newVal;

      // this.$log.debug(`CampoRigaDoc.persistValue this.$refs[${this.idRiga}_${this.nomeCampo}].dataset: `, this.$refs[`${this.idRiga}_${this.nomeCampo}`].dataset)

      this.keepFocusOnMe(evt);
      this.persistRiga(this.idRiga);
    },
    getSelectLabel(k, v) {
      const { primo, secondo } =
        this.opts.invertSelectLabel === true
          ? { primo: k, secondo: v }
          : { primo: v, secondo: k };
      return primo === secondo ? `${primo} %` : `${primo} - ${secondo}`;
    },
  },
};
</script>

<style
  scoped
  src="@/styles/custom/components/dettaglio_documento/righe/_campo_riga.scss"
  lang="scss"
></style>
