<template>
  <div v-frag>
    <h2 class="dati-documento">
      Cliente
      <span id="required-note" v-if="show.form"
        >* I campi contrassegnati da un asterisco sono obbligatori</span
      >
      <AzioniClienteSpSa
        v-if="(show.form || isClienteCompleto) && isModificabile"
        :isClienteCompleto="isClienteCompleto"
      />
    </h2>
    <fieldset>
      <RicercaClienteSpSa
        v-if="show.ricerca"
        :clienti="elencoClienti"
        @risultati="popolaElencoClienti"
      />
      <DatiClienteSpSa v-if="show.dati" :readonly="!isModificabile" />
      <FormSchemaNative
        v-show="show.form"
        v-noac
        ref="formCliente"
        id="form-cliente"
        name="form-cliente"
        :schema="SchemaCliente"
        :key="cliente.id"
        v-model="cliente"
      >
        <fieldset>
          <FormSchemaNative
            ref="formIndirizzo"
            id="form-indirizzo"
            :schema="SchemaIndirizzoCliente"
            v-model="sede"
          />
        </fieldset>
      </FormSchemaNative>
      <div id="conferma-actions" v-show="show.form">
        <span class="avviso-bottom">{{
          !isClienteCompleto
            ? "Riempire tutti i campi contrassegnati dall'asterisco per procedere"
            : ""
        }}</span>
        <button
          id="conferma-dati-cliente"
          @click.prevent="toggleEditCliente"
          :class="isClienteCompleto ? null : 'torna-alla-ricerca'"
        >
          {{
            isClienteCompleto
              ? "Conferma dati cliente"
              : "Torna alla ricerca clienti"
          }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
const NAME = "ClienteDettaglioDocumentoSpSa";

import { mapGetters, mapActions } from "vuex";
import { Forms, R } from "mida4-web-app-utils";
import { hasCliente } from "mida4-fattura-rapida-spese-sanitarie/doc";
import SchemaCliente from "@/entities/schemas/cliente.json";
import SchemaIndirizzo from "@/entities/schemas/indirizzo.json";
import NazioniIndirizzo from "@/entities/enums/nazioniIndirizzo";

import FormSchemaNative from "@formschema/native";
import AzioniClienteSpSa from "@/components/DettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa/AzioniCliSpSa";
import RicercaClienteSpSa from "@/components/DettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa/RicercaClienteSpSa";
import DatiClienteSpSa from "@/components/DettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa/DatiClienteSpSa";

const SchemaIndirizzoCliente = SchemaIndirizzo;
SchemaIndirizzoCliente.properties.nazione.enum = NazioniIndirizzo.getEnum();
["attrs", "minLength", "maxLength"].forEach(
  (t) => delete SchemaIndirizzoCliente.properties.nazione[t]
);

export default {
  name: NAME,
  components: {
    FormSchemaNative,
    AzioniClienteSpSa,
    RicercaClienteSpSa,
    DatiClienteSpSa,
  },
  data() {
    return {
      NAME,
      SchemaCliente,
      SchemaIndirizzoCliente,
      elencoClienti: null,
      caret: null,
    };
  },

  mounted() {
    /// codice duplicato: cercare componente FormClienteSpSa
    if (!this.sede.nazione) {
      this.sede.nazione = "IT";
    }
  },

  updated() {
    // this.$log.debug("updated form cliente", JSON.stringify(this.caret));
    this.$nextTick(() => {
      if (this.show.form) {
        //this.$log.debug("restore from updated.$nextTick ?");
        Forms.riordinaFormClienteDocumento(this, this.DEFAULTS.DESTINAZIONE);
        Forms.highlightRequired([
          ...this.requiredFields[0],
          ...this.requiredFields[1],
        ]);

        this.listenFormCliente(this.show.form);

        if (this.show.form && this.caret !== null) {
          Forms.restoreCaretPosition(this.caret);
        }
      } else if (this.show.ricerca) {
        document.querySelector("[name=ricercaCliente]")?.focus();
      }
    });
  },
  computed: {
    ...mapGetters({
      isModificabile: "fatture/isAttivoModificabile",
      requiredFields: "elaboranda/getClienteRequired",
      show: "elaboranda/showCliente",
      getCliente: "elaboranda/cliente",
      getSedeCliente: "elaboranda/sedeCliente",
      isClienteCompleto: "elaboranda/isClienteCompleto",
    }),
    cliente: {
      get() {
        return this.getCliente;
      },
      set(cli) {
        this.setCliente(cli);
      },
    },
    sede: {
      get() {
        return this.getSedeCliente;
      },
      set(sede) {
        this.setSedeCliente(sede);
      },
    },
    salvabile() {
      return hasCliente(this.cliente, this.requiredFields);
    },
  },
  methods: {
    ...mapActions({
      setCliente: "elaboranda/setCliente",
      setSedeCliente: "elaboranda/setSedeCliente",
      setShowCliente: "elaboranda/setShowCliente",
      toggleEditCliente: "elaboranda/toggleEditCliente",
      setIsClienteCompleto: "elaboranda/setIsClienteCompleto",
    }),

    popolaElencoClienti(elenco) {
      this.elencoClienti = elenco;
    },

    listenFormCliente(isAdd = true) {
      ["codiceFiscale", "provincia", "comune", "paesePartitaIVA"].forEach(
        (fName) =>
          isAdd
            ? document
                .querySelector(`input[name=${fName}]`)
                .addEventListener("input", Forms.evTargetToUpper)
            : document
                .querySelector(`input[name=${fName}]`)
                .removeEventListener("input", Forms.evTargetToUpper)
      );
      document.querySelectorAll("[name=form-cliente] input").forEach((el) => {
        el.addEventListener("keydown", (e) => {
          this.caret = { id: e.target.id, pos: e.target.selectionEnd + 1 };
          // this.$log.debug("caret", this.caret);
          // this.$nextTick(this.setIsClienteCompleto);
        });
        el.addEventListener("blur", () => {
          // this.$log.debug('blur')
          this.$emit("input", R.clone(this.cliente));
        });
      });
    },
  },
  watch: {
    cliente: {
      deep: true,
      handler() {
        // console.log("cliente", this.cliente)
        // this.$log.debug('cliente changed')
        this.$nextTick(this.setIsClienteCompleto);
      },
    },
  },
};
</script>

