<template>
  <div
    id="dettaglio-cliente"
    @click="readonly ? null : toggleEdit()"
    :class="readonly ? 'readonly' : undefined"
    v-tooltip="readonly ? undefined : 'Click per modificare'"
  >
    <span id="denominazione"
      >{{ cliente.cognomeDenom }} {{ cliente.nome }}</span
    >
    <span id="dati-fiscali"
      >C.F. {{ cliente.codiceFiscale }}
      {{ emptyPIVA ? null : `- P.IVA ${cliente.partitaIVA}` }}</span
    >
    <span id="indirizzo r1" v-if="hasIndirizzo"
      >{{ cliente.sede.via }} {{ cliente.sede.numeroCivico }}</span
    >
    <span id="indirizzo r2" v-if="hasIndirizzo"
      >{{ cliente.sede.cap }} {{ cliente.sede.comune }} ({{
        cliente.sede.provincia
      }}) - {{ cliente.sede.nazione }}</span
    >
    <span id="contatti" v-if="hasContatti"
      >Tel: {{ cliente.telefono }} | email: {{ cliente.email }}</span
    >
    <span id="consenso">
      <span v-if="cliente.opposizioneInvioSTS === true">
        <font-awesome-icon id="negato" icon="times" />Non acconsente
      </span>
      <span v-else
        ><font-awesome-icon id="concesso" icon="check" />Acconsente</span
      >
      all'invio dei dati al Sistema TS.
    </span>
  </div>
</template>

<script>
const NAME = "DatiClienteSpSa";

import { R } from "mida4-web-app-utils";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["readonly"],
  data() {
    return {
      NAME: NAME,
    };
  },
  computed: {
    ...mapGetters({
      getCliente: "elaboranda/cliente",
    }),
    cliente: {
      get() {
        return this.getCliente;
      },
      set(cli) {
        this.setCliente(cli);
      },
    },
    hasIndirizzo() {
      return (
        this.cliente.sede !== null &&
        ![
          "via",
          "numeroCivico",
          "cap",
          "comune",
          "provincia",
          "nazione",
        ].reduce(
          (mancanti, c) =>
            mancanti ||
            R.isNil(this.cliente.sede[c]) ||
            R.isEmpty(this.cliente.sede[c]),
          false
        )
      );
    },
    hasContatti() {
      return !["telefono", "email"].reduce(
        (mancanti, c) =>
          mancanti ||
          R.isNil(this.cliente[c]) ||
          R.isEmpty(this.cliente.sede[c]),
        false
      );
    },
    emptyPIVA() {
      return (
        R.isNil(this.cliente.partitaIVA) || R.isEmpty(this.cliente.partitaIVA)
      );
    },
  },
  methods: {
    ...mapActions({
      setCliente: "elaboranda/setCliente",
      toggleEdit: "elaboranda/toggleEditCliente",
    }),
  },
};
</script>

<style src="@/styles/custom/components/dettaglio_documento/cliente/_dati.scss" lang="scss"></style>