<template>
  <div id="azioni-cliente">
    <font-awesome-icon v-if="clienteCompleto" :icon="chiudi.ico" @click="toggleEdit" v-tooltip="chiudi.txt" />
    <font-awesome-icon icon="trash" class="delete" @click="resetDoc()" v-tooltip="'Altro cliente'"/>
  </div>
</template>

<script>

const NAME = 'AzioniClienteSpSa'

import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['clienteCompleto'], // TODO verificare perchè c'è questo e anche il getter vuex
  data(){
    return {
      NAME: NAME
    }
  },
  computed: {
    ...mapGetters({
      isClienteCompleto: 'elaboranda/isClienteCompleto',
      showCliente: 'elaboranda/showCliente'
    }),
    chiudi() {
      let params = {}
      if(this.showCliente.form){
        if(this.clienteCompleto){
          params.ico = 'check'
          params.txt = 'Conferma Dati Cliente'
        }
        else {
          params.ico = 'times-circle'
          params.txt = 'Annulla Modifica dati'
        }
      }
      else {
        params.ico = 'edit'
        params.txt = 'Modifica dati'
      }
      return params
    }
  },
  methods: {
    ...mapActions({
      toggleEdit: 'elaboranda/toggleEditCliente',
      resetDoc: 'elaboranda/resetDoc',
    })
  }
}
</script>

<style src="@/styles/custom/components/dettaglio_documento/cliente/_azioni.scss" lang="scss"></style>