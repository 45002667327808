<template>
  <div v-frag>
    <ul v-if="statoInvioDoc" :class="statoClass" id="lista-stato">
      <li>
        <font-awesome-icon
          id="icona-lista-stato"
          v-if="bannerProps.ico !== null"
          :icon="bannerProps.ico"
          :color="bannerProps.icoColor"
          :class="aggiornamentoSDI ? 'fa-spin' : null"
        />
        <span>
          {{ bannerProps.txt }}
          <span
            id="mostra-elenco-messaggi"
            @click="showMessaggi = true"
            v-if="hasElencoMessaggi && showMessaggi === false"
          >
            <font-awesome-icon
              id="mostra-elenco-messaggi-ico"
              icon="info-circle"
              v-if="hasElencoMessaggi && showMessaggi === false"
            />
            <span v-if="isSmallScreen">Mostra log trasmissione</span>
          </span>
        </span>
      </li> 
    </ul>


    <div id="container-elenco-messaggi" v-if="hasElencoMessaggi && showMessaggi === true">
      <font-awesome-icon
        id="nascondi-elenco-messaggi"
        icon="times"
        @click="showMessaggi = false"
      />
      <h5>Log trasmissione (ora UTC)</h5>
      <ul>
        <li v-for="(log, i) in elencoMessaggi" :key="`log-${i}`">
          <font-awesome-icon
            :icon="getIcon(log.logLevel).ico"
            :color="getIcon(log.logLevel).color"
            class="logLevel"
          />
          <span class="quando">{{ formatData(log.quando) }}</span>
          <MessaggiDocumentoSpSa :messaggi="log.descrizione" />
        </li>
      </ul>
      <hr class="sep" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { R, REGEXES } from "mida4-web-app-utils";
import { StatoInvio } from "mida4-fattura-rapida-spese-sanitarie";
import { formatDataLog } from "mida4-fattura-rapida-spese-sanitarie/doc";
import MessaggiDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/MessaggiDocumentoSpSa";

const NAME = "BannerStatoInvioDocumentoSpSa";

export default {
  name: NAME,
  props: ["statoInvioDoc"],
  data() {
    return {
      showMessaggi: false,
    };
  },
  created() {
    this.showMessaggi = [
      StatoInvio.ESITO_ERR,
      StatoInvio.STS_UNAVAILABLE,
    ].includes(this.statoInvioDoc);
  },
  components: {
    MessaggiDocumentoSpSa,
  },
  methods: {
    formatData: (d) => formatDataLog(d.replace(REGEXES.PUNTO_E_ALTRO, "")),
    getIcon: (lvl) => {
      switch (lvl) {
        case "ERROR":
          return { ico: "times-circle", color: "red" };
        case "WARN":
          return { ico: "exclamation-triangle", color: "orange" };
        case "INFO":
          return { ico: "info-circle", color: "darkblue" };
      }
    },
  },
  computed: {
    ...mapGetters({
      messaggi: "fatture/ultimiMessaggi",
      elencoMessaggi: "fatture/elencoMessaggi",
    }),
    statoClass() {
      return this.statoInvioDoc.toLowerCase();
    },
    isSmallScreen() {
        return this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.thresholds.xs;
    },
    _bannerPropsList() {
      return new Map([
        [StatoInvio.LOCALE, undefined],
        [
          StatoInvio.DA_INVIARE,
          { txt: "Da inviare", ico: "share-square", icoColor: "dimgray" },
        ],
        [
          StatoInvio.STS_UNAVAILABLE,
          {
            txt: "Servizio TS temporaneamente non disponibile. Ritentare l'invio",
            ico: "share-square",
            icoColor: "#9d776d",
          },
        ],
        [
          StatoInvio.ATTESA_ESITO,
          {
            txt: this.aggiornamentoSDI
              ? "Richiesta aggiornamento esito invio"
              : "In attesa dell'esito invio",
            ico: "spinner",
            icoColor: "dimgray",
          },
        ],
        [
          StatoInvio.SDI_TIMEOUT,
          {
            txt: "In attesa dell'esito invio - timeout SDI",
            ico: "spinner",
            icoColor: "dimgray",
          },
        ],
        [
          StatoInvio.ESITO_OK,
          {
            txt: "Inviato correttamente",
            ico: "check-circle",
            icoColor: "green",
          },
        ],
        [
          StatoInvio.ESITO_WARN,
          {
            txt: "Inviato correttamente",
            ico: "check-circle",
            icoColor: "green",
          },
        ],
        [
          StatoInvio.ESITO_ERR,
          {
            txt: `Il Servizio Tessera Sanitaria ha restituito un errore - Inoltro fallito.`,
            ico: "times",
            icoColor: "red",
          },
        ],
      ]);
    },
    bannerProps() {
      // this.$log.debug(
      //   `bannerProps ${this.statoInvioDoc}`,
      //   this._bannerPropsList.get(this.statoInvioDoc)
      // );
      return this._bannerPropsList.get(this.statoInvioDoc);
    },
    hasMessaggi() {
      return !(R.isEmpty(this.messaggi) || R.isNil(this.messaggi));
    },
    hasElencoMessaggi() {
      return !(R.isEmpty(this.elencoMessaggi) || R.isNil(this.elencoMessaggi));
    },
    tipoMessaggi() {
      if (this.statoInvioDoc === this.stati.ESITO_WARN) return "segnalazioni";
      else return "errori";
    },
  },
};
</script>

<style src="@/styles/custom/components/dettaglio_documento/_banner_stato_invio.scss" lang="scss"></style>