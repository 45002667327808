<template>
  <li class="risultato" @click="seleziona(cliente)">
    <MatchRigaRicercaSpSa :str="cfCliente" />&nbsp;<MatchRigaRicercaSpSa
      :str="nomeCliente"
    />
  </li>
</template>

<script>
import { toTitleCase } from "mida4-web-app-utils";
import MatchRigaRicercaSpSa from "@/components/DettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa/MatchRigaRicercaSpSa";
import { mapActions } from "vuex";

const NAME = "MatchRigaRicercaSpSa";

export default {
  components: { MatchRigaRicercaSpSa },
  props: ["cliente", "searchStr"],
  data() {
    return {
      NAME: NAME,
    };
  },
  computed: {
    nomeCliente() {
      return this.wrapMatch(
        toTitleCase(`${this.cliente.cognomeDenom} ${this.cliente.nome}`)
      );
    },
    cfCliente() {
      return this.wrapMatch(this.cliente.codiceFiscale);
    },
  },
  methods: {
    ...mapActions({
      seleziona: "elaboranda/impostaCliente",
    }),
    getStrMatchArray(str, search) {
      return ((s, si, sea) =>
        si.includes(sea)
          ? [
              s.substr(0, si.indexOf(sea)),
              s.substr(si.indexOf(sea), sea.length),
              s.substr(si.indexOf(sea) + sea.length),
            ]
          : [s])(str, str.toLowerCase(), search.toLowerCase());
    },
    wrapMatch(str) {
      let [pre, match, post] = this.getStrMatchArray(str, this.searchStr);
      return {
        pre,
        match,
        post,
      };
    },
  },
};
</script>

<style src="@/styles/custom/components/dettaglio_documento/cliente/_riga_ricerca.scss" lang="scss"></style>