const TipiSpesa = Object.freeze({
  TK: "Ticket",
  "TK-1": "Ticket Pronto Soccorso",
  FC: "Farmaco",
  FV: "Farmaco Veterinario",
  SV: "Spese Veterinarie",
  SP: "Prestazioni Sanitarie",
  AD: "Dispositivo Medico CE - Acquisto/Affitto",
  AS: "Analisi (ECG, spirometria, analisi sangue,...) [Non più valido dal 2021]",
  SR: "Visita ambulatoriale",
  "SR-2": "Visita Intramoenia",
  CT: "Cure Termali",
  PI: "Protesica",
  IC: "Chirurgia Estetica",
  AA: "Altre Spese",
  ALL: 16383,
  fromBitMask(mask) {
    return Object.entries(this).reduce(
      (tipiSp, el, i) => ((2 ** i) & mask ? [el, ...tipiSp] : tipiSp),
      []
    );
  },
});

export default TipiSpesa;
