import { render, staticRenderFns } from "./RicercaClienteSpSa.vue?vue&type=template&id=a53988da"
import script from "./RicercaClienteSpSa.vue?vue&type=script&lang=js"
export * from "./RicercaClienteSpSa.vue?vue&type=script&lang=js"
import style0 from "@/styles/custom/components/dettaglio_documento/cliente/_ricerca.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports