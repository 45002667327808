<template>
  <div>
    <p class="ricerca-cliente">
      Inserire Cognome o Codice Fiscale o strisciare la Tessera Sanitaria
    </p>
    <div class="ricerca-cliente">
      <div id="loader-clienti" v-if="loadingClienti">
        <font-awesome-icon icon="spinner" spin />
        <span>Caricamento lista clienti in corso</span>
      </div>
      <input
        :disabled="loadingClienti ? true : undefined"
        type="text"
        autocomplete="off"
        name="ricercaCliente"
        @keydown="rilevaFrecce"
        @input.prevent="ricercaCliente"
        ref="inputRicercaCliente"
        tabindex="0"
      />
      <input type="hidden" name="inputLettore" ref="inputLettore" />
      <button @click.prevent="nuovoCliente(null)" tabindex="1">Nuovo</button>
    </div>
    <div id="risultati-ricerca" v-if="hasMatch">
      <ul @keydown="rilevaFrecce" ref="listaRisultati">
        <RigaRicercaClienteSpSa
          v-for="(c, i) in clientiMatches()"
          :tabindex="i + 2"
          :key="c.id"
          :cliente="c"
          :searchStr="searchStr()"
        />
      </ul>
    </div>
    <div id="risultati-ricerca" v-else-if="noResults">
      <ul>
        <li>Nessuna corrispondenza</li>
      </ul>
    </div>
  </div>
</template>

<script>
const NAME = "RicercaClienteSpSa";

import { R, allCaps, Pulsanti, REGEXES } from "mida4-web-app-utils";

import { mapGetters, mapActions, mapMutations } from "vuex";
import RigaRicercaClienteSpSa from "@/components/DettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa/RigaRicercaClienteSpSa";

export default {
  props: ["clienti"],
  components: {
    RigaRicercaClienteSpSa,
  },
  data() {
    return {
      NAME: NAME,
      elencoClienti: null,
      hasMatch: false,
      noResults: false,
      matchIndex: 0,
      processingReaderInput: false,
      loadingClienti: false,
    };
  },
  computed: {
    ...mapGetters({
      clientiSalvati: "clienti/getClienti",
      getShowCliente: "elaboranda/showCliente",
    }),
    showCliente: {
      get() {
        return this.getShowCliente;
      },
      set(cli) {
        return this.setShowCliente(cli);
      },
    },
  },
  async created() {
    if (!R.isNil(this.clienti) && !R.isEmpty(this.clienti)) {
      this.elencoClienti = [...this.clienti];
    } else {
      this.loadingClienti = true;
      await this.recuperaClienti();
      this.elencoClienti = this.clientiSalvati;
      this.$emit("risultati", this.elencoClienti);
      this.loadingClienti = false;
      // this.$log.info('ricercaCliente - caricati clienti', this.elencoClienti.length)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startCFReaderListener();
    });
  },
  beforeDestroy() {
    this.stopCFReaderListener();
    this.tearDownInputRicerca();
  },
  methods: {
    ...mapMutations({
      memorizzaClienti: "clienti/setElenco",
    }),
    ...mapActions({
      recuperaClienti: "clienti/recuperaElenco",
      resetDoc: "elaboranda/resetDoc",
      seleziona: "elaboranda/impostaCliente",
      setShowCliente: "elaboranda/setShowCliente",
      setCliente: "elaboranda/setCliente",
    }),
    evTrgtOnlyAlNumToUp(e) {
      e.target.value = R.toUpper(
        e.target.value.replace(REGEXES.NO_ALPHANUM, "")
      );
    },
    evGetOnlyAlNum(e) {
      const unwanted =
        (e.keyCode || e.which || e.charCode || 0) === Pulsanti.ENTER ||
        e.key.match(REGEXES.ALPHANUM) === null;
      if (unwanted) {
        e.preventDefault();
        return false;
      }
    },
    initInputRicerca() {
      const campoInput = this.$refs.inputRicercaCliente;
      campoInput.focus();
      campoInput.addEventListener("keypress", this.evGetOnlyAlNum);
      campoInput.addEventListener("input", this.evTrgtOnlyAlNumToUp);
    },
    tearDownInputRicerca() {
      const campoInput = this.$refs.inputRicercaCliente;
      campoInput.removeEventListener("keypress", this.evGetOnlyAlNum);
      campoInput.removeEventListener("input", this.evTrgtOnlyAlNumToUp);
    },
    startCFReaderListener() {
      document.addEventListener("keypress", this.processCFReader);
    },
    stopCFReaderListener() {
      document.removeEventListener("keypress", this.processCFReader);
    },
    processCFReader(e) {
      if (this.$refs.inputLettore === undefined) return;
      const controlCharMatches = this.$refs.inputLettore.value.match(/_/g);

      if (this.processingReaderInput === true) {
        e.preventDefault();
        e.stopPropagation();
        this.$refs.inputLettore.value += e.key;
        this.$refs.inputRicercaCliente.value = "";
      }

      if (controlCharMatches && controlCharMatches.length == 2) {
        this.processingReaderInput = false;
        this.ricercaDaReader();
      }
      if (e.key === "%") {
        this.processingReaderInput = true;
        this.$refs.inputLettore.value = "";
        this.$refs.inputRicercaCliente.value = "";

        setTimeout(() => {
          this.processingReaderInput = false;
          if (this.$refs.inputLettore !== undefined) {
            this.$refs.inputLettore.value = "";
          }
          if (this.$refs.inputRicercaCliente !== undefined) {
            this.$refs.inputRicercaCliente.value = "";
          }
        }, 500);
      }
    },
    searchStr() {
      const v = this.$refs.inputRicercaCliente;
      return R.isNil(v) ? "" : v.value.toUpperCase();
    },
    clientiMatches() {
      const s = this.searchStr();
      return this.elencoClienti.filter(
        (c) =>
          c.cognomeDenom.toUpperCase().substr(0, s.length) === s ||
          c.codiceFiscale.toUpperCase().substr(0, s.length) === s
      );
    },
    getReaderInput() {
      const r = this.$refs.inputLettore.value.split("_").shift(),
        cognomeNome = () => allCaps(r.substr(16).toLowerCase()).split("  ");

      return {
        codiceFiscale: r.substr(0, 16),
        cognomeDenom: cognomeNome().shift().replace(REGEXES.NO_ALPHA, " "),
        nome: cognomeNome().pop().replace(REGEXES.NO_ALPHA, " "),
      };
    },
    nuovoCliente(cli) {
      // this.$log.debug("nuovoCliente", cli);
      if (!this.loadingClienti) {
        this.resetDoc();
        if (cli) {
          this.setCliente(cli);
        }
        this.showCliente = "form";
      }
    },
    rilevaFrecce(e) {
      if (Pulsanti.isFrecciaOInvio(e.which) && this.hasMatch) {
        if (e.target.tagName.toUpperCase() === "LI") {
          switch (e.which) {
            case Pulsanti.SU:
              if (this.matchIndex === 0) {
                this.$refs.inputRicercaCliente.focus();
                return;
              }
              this.matchIndex = this.matchIndex - 1;
              break;
            case Pulsanti.GIU:
              this.matchIndex =
                this.matchIndex +
                (this.matchIndex == this.clientiMatches().length - 1 ? 0 : 1);
              break;
            case Pulsanti.DX:
            case Pulsanti.ENTER:
              this.seleziona(this.clientiMatches()[this.matchIndex]);
              return;
            case Pulsanti.SX:
            default:
          }
        }
        const mi = this.matchIndex;
        this.$refs.listaRisultati.querySelectorAll("li")[mi].focus();
      }
    },
    ricercaCliente() {
      if (this.searchStr().length >= 3) {
        const found = this.clientiMatches().length > 0;
        this.hasMatch = found;
        this.noResults = !found;
      } else {
        this.hasMatch = false;
        this.noResults = false;
      }
    },
    ricercaDaReader() {
      const { codiceFiscale, cognomeDenom, nome } = this.getReaderInput();
      const cliente = this.elencoClienti.find(
        (c) => c.codiceFiscale.toUpperCase() == codiceFiscale
      );

      if (!R.isNil(cliente)) {
        this.seleziona(cliente);
      } else {
        this.nuovoCliente({ codiceFiscale, cognomeDenom, nome });
      }

      // let [ evt, payload ] = cliente !== undefined
      //   ? ['selezionaCliente', cliente]
      //   : ['nuovoCliente', { codiceFiscale, cognomeDenom, nome }]

      // bus.$emit(evt, payload)
    },
  },
  watch: {
    loadingClienti(isLoading) {
      if (!isLoading) {
        this.$nextTick(() => {
          // la chiamata che segue associa i listener al campo ricerca
          //
          // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
          //
          // If the function or object is already in the list of event listeners
          // for this target, the function or object is not added a second time.
          this.initInputRicerca();
        });
      }
    },
  },
  notifications: {
    showError: {
      title: "Errore",
      message: "Si è verificato un errore",
      type: "error",
    },
  },
};
</script>

<style
  src="@/styles/custom/components/dettaglio_documento/cliente/_ricerca.scss"
  lang="scss"
></style>

