<template>
  <section id="nuovo-documento">
    <FormSchemaNative
      ref="formDettaglioDoc"
      id="form-nuovo-documento"
      :schema="SchemaFattura"
      v-model="documento"
    >
      <ClienteDettaglioDocumentoSpSa :key="formClienteKey" />
    </FormSchemaNative>
    <h2 class="dati-documento">
      Righe fattura
      <span v-if="isModificabile && isClienteCompleto && !showCliente.form">
        Per <strong>modificare una riga già inserita</strong> fare
        <strong>click sopra la riga stessa</strong>
      </span>
    </h2>
    <div v-if="!isClienteCompleto" class="istruzioni">
      I campi per l'inserimento saranno visibili dopo aver inserito i dati del
      cliente
    </div>
    <TabellaRigheDettaglioDocumentoSpSa
      v-if="isClienteCompleto"
      ref="righeDocumento"
      @input="setBollo"
    />
    <AzioniDettaglioDocumentoSpSa
      :mode="azioniMode"
      @inviaDoc="inviaDoc"
      @eliminaDoc="eliminaDoc"
    />
    <ProgressInvioDocumentoSpSa
      v-if="progressInvio != null"
      :fase="progressInvio"
      :servizio="documento.destinazioneFattura"
    />
  </section>
</template>

<script>
const NAME = "FormDettaglioDocumentoSpSa";

import { format, parse, isValid } from "date-fns";
import StatusCodes from "http-status-codes";
import { interpret } from "xstate";
import { mapGetters, mapActions } from "vuex";

import { DateDefaults, Forms, R } from "mida4-web-app-utils";

import {
  ModalitaPagamento,
  InvioService,
} from "mida4-fattura-rapida-spese-sanitarie";

import {
  hasPIVA,
  getClienteRequiredFields,
} from "mida4-fattura-rapida-spese-sanitarie/doc";

import FormSchemaNative from "@formschema/native";
import ClienteDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa/ClienteDettaglioDocumentoSpSa";
import TabellaRigheDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/TabellaRigheDettaglioDocumentoSpSa/TabellaRigheDettaglioDocumentoSpSa";
import AzioniDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/AzioniDettaglioDocumentoSpSa";
import ProgressInvioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/ProgressInvioDocumentoSpSa";

import { generaElencoMessaggi } from "@/components/DettaglioDocumentoSpSa/MessaggiDocumentoSpSa";

import SchemaCliente from "@/entities/schemas/cliente.json";
import SchemaIndirizzo from "@/entities/schemas/indirizzo.json";
import SchemaFattura from "@/entities/schemas/fattura.json";
import SchemaRigaFattura from "@/entities/schemas/riga-fattura.json";

import FasiInvio from "@/entities/enums/fasi-invio";

SchemaFattura.properties.modalitaPagamento.enum = ModalitaPagamento.getEnum();

export default {
  name: NAME,
  components: {
    FormSchemaNative,
    ClienteDettaglioDocumentoSpSa,
    TabellaRigheDettaglioDocumentoSpSa,
    AzioniDettaglioDocumentoSpSa,
    ProgressInvioDocumentoSpSa,
  },
  data() {
    return {
      NAME,
      SchemaFattura,
      progressInvio: null,
      cassaOritenutaKeyPart: null,
    };
  },
  created() {
    this.addebitoBollo = R.isNil(this.storedUserDefaults)
      ? false
      : this.storedUserDefaults.isAddebitoBollo;
    this.setRegimeFiscale(this.datiUtente.regimeFiscale);
    // this.$log.debug("d", this.documento.regimeFiscale);
    if (!R.isNil(this.docAttivo)) {
      this.elaboraDocAttivo();
    }
    this.clienteRequiredFields = [
      getClienteRequiredFields(hasPIVA(this.documento.cliente), SchemaCliente),
      SchemaIndirizzo.required,
    ];
  },
  mounted() {
    // this.$log.debug("mounted formdoc doc attivo nil ?", R.isNil(this.docAttivo));
    if (R.isNil(this.docAttivo)) {
      const oggi = format(new Date(), DateDefaults.formats.CLIENT);

      this.setDestinazioneFattura(this.DEFAULTS.DESTINAZIONE);
      this.setDataDocumento(oggi);
      this.setDataPagamento(oggi);

      Forms.impostaDefaultFormDocumento(oggi, this.DEFAULTS.DESTINAZIONE);
    }
    Forms.riordinaFormDocumento(this, this.DEFAULTS.DESTINAZIONE);
    Forms.sostituisciControlloDest(); // TODO da eliminare
    this.toggleCampiNotaCredito();
    if (this.isModificabile) {
      document
        .querySelectorAll("input")
        .forEach((i) => Forms.handleErrors(i, this.$refs.formDettaglioDoc));
    } else {
      Forms.setReadOnlyIntestazioneFattura(this);
    }
    const campiData = [
      "dataDocumento",
      "dataPagamento",
      "rimborsoDataDocumento",
    ];
    for (let c of campiData) {
      const el = document.querySelector(`input[name=${c}]`);
      if (!R.isNil(el)) {
        el.addEventListener("blur", this.validateData);
      }
    }
  },
  beforeDestroy() {
    const campiData = [
      "dataDocumento",
      "dataPagamento",
      "rimborsoDataDocumento",
    ];
    for (let c of campiData) {
      const el = document.querySelector(`input[name=${c}]`);
      if (!R.isNil(el)) {
        el.removeEventListener("blur", this.validateData);
      }
    }
  },
  computed: {
    ...mapGetters({
      // auth
      credenzialiSTS: "auth/getCredenzialiSTS",
      // fatture
      activeId: "getActiveId",
      docAttivo: "fatture/getDocAttivo",
      isModificabile: "fatture/isAttivoModificabile",
      isEliminabile: "fatture/isAttivoEliminabile",
      getFattura: "fatture/getFattura",
      idUltimaFattura: "fatture/getLastId",
      // documento in elaborazione
      documento: "elaboranda/documento",
      formClienteKey: "elaboranda/formClienteKey",
      getAddebitoBollo: "elaboranda/addebitoBollo",
      getImpostaBollo: "elaboranda/impostaBollo",
      getDocumento: "elaboranda/documento",
      getCliente: "elaboranda/cliente",
      getRighe: "elaboranda/righe",
      statoRigaModificanda: "elaboranda/statoRigaModificanda",
      daInviare: "elaboranda/daInviare",
      isClienteCompleto: "elaboranda/isClienteCompleto",
      getShowCliente: "elaboranda/showCliente",
      getClienteRequired: "elaboranda/getClienteRequired",
      // user
      storedUserDefaults: "user/getDefaults",
      datiUtente: "user/getData",
    }),
    impostaBollo: {
      get() {
        return this.getImpostaBollo;
      },
      set(val) {
        this.setImpostaBollo(val);
      },
    },
    addebitoBollo: {
      get() {
        return this.getAddebitoBollo;
      },
      set(val) {
        this.setAddebitoBollo(val);
      },
    },
    documento: {
      get() {
        return this.getDocumento;
      },
      set(doc) {
        this.setDocumento(doc);
      },
    },
    righe: {
      get() {
        return this.getRighe;
      },
      set(righe) {
        this.setRighe(righe);
      },
    },
    cliente: {
      get() {
        return this.getCliente;
      },
      set(cli) {
        this.setCliente(cli);
      },
    },
    showCliente: {
      get() {
        return this.getShowCliente;
      },
      set(part) {
        this.setShowCliente(part);
      },
    },
    clienteRequiredFields: {
      get() {
        return this.getClienteRequired;
      },
      set(fields) {
        this.setClienteRequired(fields);
      },
    },
    azioniMode() {
      return {
        modificabile: this.isModificabile,
        inviabile: true, // TODO rimuovere
        eliminabile: this.isEliminabile,
      };
    },
    destEditable() {
      return false;
    },
  },
  methods: {
    ...mapActions({
      memorizzaCliente: "clienti/insertCliente",
      memorizzaDoc: "fatture/insertDoc",
      setDocAttivo: "fatture/setDocAttivo",
      eliminaUltimoDoc: "fatture/eliminaUltimoDoc",
      resetDocAttivo: "fatture/resetDocAttivo",
      // documento in elaborazione

      setDestinazioneFattura: "elaboranda/setDestinazioneFattura",
      setDataDocumento: "elaboranda/setDataDocumento",
      setDataPagamento: "elaboranda/setDataPagamento",
      setRegimeFiscale: "elaboranda/setRegimeFiscale",
      setRimborsoDataDocumento: "elaboranda/setRimborsoDataDocumento",
      setRimborsoNumeroDocumento: "elaboranda/setRimborsoNumeroDocumento",
      setPagamentoTracciabile: "elaboranda/setPagamentoTracciabile",

      setImpostaBollo: "elaboranda/setImpostaBollo",
      setAddebitoBollo: "elaboranda/setAddebitoBollo",
      setBollo: "elaboranda/setBollo",
      applicaAddebitoBollo: "elaboranda/applicaAddebitoBollo",
      elaboraDocAttivo: "elaboranda/elaboraDocAttivo",
      setDocumento: "elaboranda/setDocumento",
      setRighe: "elaboranda/setRighe",
      setCliente: "elaboranda/setCliente",
      nuovoCliente: "elaboranda/nuovoCliente",
      resetDoc: "elaboranda/resetDoc",
      setShowCliente: "elaboranda/setShowCliente",
      setClienteRequired: "elaboranda/setClienteRequired",
      setIsClienteCompleto: "elaboranda/setIsClienteCompleto",
    }),
    validateData(evt) {
      let parsed;
      let valid = false;
      try {
        parsed = parse(
          evt.target.value,
          DateDefaults.formats.CLIENT,
          new Date()
        );
        valid = isValid(parsed) && parsed.getFullYear() >= 1980;
      } catch (error) {
        this.$log.debug(
          `Errore nell'elaborazione della ${evt.target.name}`,
          error
        );
      }
      if (!valid) {
        evt.preventDefault();
        this.$refs.formDettaglioDoc.setErrorMessage(`Data non valida`);
        evt.target.classList.add("has-error");
        return false;
      }
    },
    toggleCampiNotaCredito() {
      const intestazione = document.querySelector(
        "#documento>h2:first-of-type"
      );
      // Per le comunicazioni spese sanitarie è necessario indicare
      // numero e data del documento fiscale a cui fa riferimento
      // la nota di credito
      const riga = document.querySelector("#riga-int-2");
      if (this.documento.tipoDocumento === "TD04") {
        riga.style.display = "grid";
        intestazione.innerHTML = intestazione.innerHTML.replace(
          "Fattura",
          "Nota di credito"
        );
      } else {
        riga.style.display = "none";
        riga.querySelectorAll("input").forEach((el) => (el.value = null));
        this.setRimborsoDataDocumento(null);
        this.setRimborsoNumeroDocumento(null);
        intestazione.innerHTML = intestazione.innerHTML.replace(
          "Nota di credito",
          "Fattura"
        );
      }
    },
    async eliminaDoc() {
      this.toggleLoader(true);

      const res = await this.$api.eliminaFattura(this.docAttivo);
      if (res.status === StatusCodes.OK) {
        this.showSuccess({
          title: "Fattura eliminata",
          message: "Operazione completata correttamente",
          didClose: async () => {
            // elimina fattura dallo store --
            await this.eliminaUltimoDoc(this.docAttivo);
            // imposta docAttivo a null
            const DELETED = true;
            await this.resetDocAttivo(DELETED);
            this.toggleLoader(false);
            this.$router.push("/");
          },
        });
      } else {
        const body = await res.json();
        this.showError({
          title: "Impossibile eliminare la fattura",
          message: body.message,
        });
        this.$log.warn(
          `il server ha impedito di eliminare il doc ${this.docAttivo.id}`,
          res.status
        );
      }
    },
    // invio
    inviaDoc() {
      const invalidDates = () =>
        document.querySelectorAll("[type=date].has-error").length > 0;
      // controllo lo stato delle cose e faccio partire l'invio
      if (
        invalidDates() ||
        !this.isClienteCompleto ||
        R.isNil(this.righe) ||
        R.isEmpty(this.righe)
      ) {
        this.showError({
          title: "Impossibile inviare",
          message:
            "Per inviare la fattura, l'intestazione deve essere corretta, i dati del cliente devono essere compilati e tutte le righe devono essere complete",
        });
        return;
      }

      const { id, mancanti, parzialmenteCompilata } = this.statoRigaModificanda;
      const errori = Forms.check([this.$refs.formDettaglioDoc]);
      // il servizio Spese Sanitarie non prevede l'invio di importi negativi
      const importoNonValido = this.documento.righe.filter(
        (r) => r.importoUnitario <= 0
      );
      if (parzialmenteCompilata) {
        if (this.showCliente.form) {
          this.toggleEditCliente(false);
        }
        this.$nextTick(() =>
          this.$refs.righeDocumento.evidenziaCampiMancanti(id, mancanti)
        );
      } else if (errori || !R.isEmpty(importoNonValido)) {
        this.showError({
          title: "Impossibile inviare",
          message:
            "Per inviare la fattura è necessario valorizzare il cliente, tutte le righe devono essere complete ed avere un importo maggiore di zero",
        });
        if (!R.isEmpty(importoNonValido)) {
          for (let { _id } of importoNonValido) {
            document
              .querySelector(`#riga-${_id} [name="importoUnitario"]`)
              .classList.add("input-error");
          }
        }
        this.$log.warn(
          "Dati non validi non invio nulla",
          errori || importoNonValido
        );
      } else {
        // nulla osta all'invio, posso iniziare la trasmissione
        // mediante una macchina a stati finiti (fsm)
        let statoOperazione = null;

        // configurazione fsm
        let options = {
          SchemaCliente,
          SchemaIndirizzo,
          SchemaFattura,
          SchemaRigaFattura,
          api: this.$api,
          credenzialiSTS: this.credenzialiSTS,
          docAttivo: this.docAttivo,
          retry: this.DEFAULTS.RITENTA_INVIO,
        };

        // operazioni da compiere quando cambia lo stato della fsm
        const processState = (state) => {
          // this.$log.info('processState: statoDoc', state.value)
          statoOperazione = state;
          this.updateProgressInvio(state);
        };

        // quando la fsm raggiunge uno stato terminale
        const finalize = () => this.gestisciEsito(statoOperazione);

        // definizione fsm
        const fsm = interpret(
          InvioService.gestioneInvio.withContext({
            ...InvioService.gestioneInvio.context,
            ...options,
            fattura: this.daInviare,
          })
        )
          .onTransition(processState)
          .onDone(finalize);

        // avvio fsm
        this.progressInvio = FasiInvio.AGGIUNGI;
        fsm.start();
      }
    },
    async gestisciEsito(op) {
      let fattClient = await InvioService.gestioneEsito({
        op: op,
        errorDialog: this.showError,
        alertDialog: this.showAlert,
        successDialog: this.showSuccess,
        onInviato: () => Forms.setReadOnlyIntestazioneFattura(this),
        generaElencoMessaggi,
      });
      if (fattClient !== undefined) {
        this.memorizzaCliente(fattClient.cliente);
        this.memorizzaDoc(fattClient);
        this.setDocAttivo(fattClient);

        if (this.$route.params.idFattura != fattClient.id) {
          this.$router.push({ params: { idFattura: fattClient.id } });
        }
      }
    },
    /**
     * Aggiorna l'indicatore di progresso in
     * base allo stato della fsm
     *
     * @param {object} state stato invio
     */
    updateProgressInvio(state) {
      switch (state.value) {
        case "daAggiungere":
        case "daAggiornare":
          break;
        case "daInviare":
          this.progressInvio = FasiInvio.INVIA;
          break;
        case "inviata":
          this.progressInvio = FasiInvio.INVIA_OK;
          break;
        case "errore":
          this.progressInvio =
            this.faseInvio === FasiInvio.AGGIUNGI
              ? FasiInvio.AGGIUNGI_KO
              : FasiInvio.INVIA_KO;
          break;
        default:
          if (state.value.failure === "invia") {
            this.progressInvio = FasiInvio.INVIA;
          } else {
            this.$log.info(
              ".onTransition((state) state.value non dovrebbe avere questo valore",
              state.value
            );
          }
      }
    },
  },
  watch: {
    progressInvio(fase) {
      if (fase === FasiInvio.AGGIUNGI || fase === FasiInvio.INVIA) {
        this.toggleLoader(true);
      } else if (
        [
          FasiInvio.AGGIUNGI_KO,
          FasiInvio.INVIA_KO,
          FasiInvio.INVIA_OK,
        ].includes(fase)
      ) {
        this.toggleLoader(false);
        this.progressInvio = null;
      }
    },
    documento: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          // this.$log.debug("watch doc", this.getRighe.length);
          this.setPagamentoTracciabile(
            ModalitaPagamento.isTracciabile(val.modalitaPagamento)
          );
          // this.inviabile = this.checkInviabile()
          this.toggleCampiNotaCredito();
        });
      },
    },
  },
  notifications: {
    showSuccess: {
      title: "Dati salvati",
      message: "I dati sono stati salvati correttamente",
      type: "success",
      html: null,
    },
    showAlert: {
      title: "Attenzione",
      message: "Si è verificata un anomalia",
      type: "warn",
      html: null,
    },
    showError: {
      title: "Errore salvataggio dati",
      message: "Si è verificato un'errore nel salvataggio dei dati",
      type: "error",
      html: null,
    },
  },
};
</script>
<style
  src="@/styles/custom/components/dettaglio_documento/_form.scss"
  lang="scss"
></style>
