<template>
  <div v-frag>
    <span>{{pre}}</span>
    <span v-if="match !== undefined" class="match">{{match}}</span>
    <span v-if="match !== undefined">{{post}}</span>
  </div>
</template>

<script>
const NAME = 'MatchRigaRicercaWebFe'
export default {
  name: NAME,
  props: ['str'],
  data(){ 
    return {
      pre: this.str.pre, 
      match: this.str.match,
      post: this.str.post
    }
  }
}
</script>