import { render, staticRenderFns } from "./ProgressInvioDocumentoSpSa.vue?vue&type=template&id=45c3f2d1"
import script from "./ProgressInvioDocumentoSpSa.vue?vue&type=script&lang=js"
export * from "./ProgressInvioDocumentoSpSa.vue?vue&type=script&lang=js"
import style0 from "@/styles/custom/components/dettaglio_documento/_progress_invio.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports