<template>
  <LayoutSpSa>
    <section
      id="documento"
      :key="(docAttivo ? docAttivo.id : 'new') + '_doc'"
      :class="isModificabile ? undefined : 'non-modificabile'"
    >
      <h2>
        {{ docAttivo ? `${nomeTipoDoc} ${activeId}` : `Nuova ${nomeTipoDoc}` }}
      </h2>
      <button
        id="stampa-doc"
        class="attivo"
        v-if="stampabile"
        @click="stampaDoc"
      >
        <font-awesome-icon class="azioni-icon" icon="print" />
        Stampa
      </button>
      <button id="xml-doc" class="attivo" v-if="stampabile" @click="scaricaXML">
        <font-awesome-icon class="azioni-icon" icon="code" />
        Scarica XML SDI
      </button>
      <!-- <button
        id="nuova-fattura-doc"
        v-if="docAttivo !== null && isNuovoDocAbilitato"
        :class="isNuovoDocAbilitato ? undefined : 'disabled'"
        @click="resetFattura"
      > -->
      <!-- <font-awesome-icon icon="file-alt" />&nbsp;Nuova
      </button> -->
      <div class="clear"></div>
      <BannerStatoInvioDocumentoSpSa
        v-if="statoInvioDoc"
        :key="getBannerKey()"
        :statoInvioDoc="statoInvioDoc"
      />
      <FormDettaglioDocumentoSpSa
        :key="docFormKey"
        :docAttivo="docAttivo"
        :isModificabile="isModificabile"
      />
    </section>
  </LayoutSpSa>
</template>

<script>
const NAME = "DettaglioDocumentoSpSa";

import { nanoid } from "nanoid";
import { R, downloadFile } from "mida4-web-app-utils";
import { StatoInvio } from "mida4-fattura-rapida-spese-sanitarie";
import {
  getStatoInvioDoc,
  getUltimiMessaggi,
  getElencoMessaggi,
} from "mida4-fattura-rapida-spese-sanitarie/doc";
import { mapGetters, mapActions } from "vuex";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import FormDettaglioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/FormDettaglioDocumentoSpSa";
import BannerStatoInvioDocumentoSpSa from "@/components/DettaglioDocumentoSpSa/BannerStatoInvioDocumentoSpSa";

export default {
  components: {
    LayoutSpSa,
    FormDettaglioDocumentoSpSa,
    BannerStatoInvioDocumentoSpSa,
  },
  props: ["idFattura"],
  data() {
    return {
      NAME: NAME,
      StatoInvio: StatoInvio,
      requestingDownload: false,
      docFormKey: null,
    };
  },
  computed: {
    ...mapGetters({
      docAttivo: "fatture/getDocAttivo",
      isModificabile: "fatture/isAttivoModificabile",
      activeId: "getActiveId",
      isNuovoDocAbilitato: "fatture/isNuovoDocAbilitato",
      datiUtente: "user/getData",
    }),
    nomeTipoDoc() {
      return this.docAttivo?.tipoDocumento === "TD04"
        ? "Nota di credito"
        : "Fattura";
    },
    isModificabile() {
      return (
        R.isNil(this.docAttivo) || StatoInvio.isModificabile(this.docAttivo)
      );
    },
    statoInvioDoc() {
      return getStatoInvioDoc(this.docAttivo);
    },
    stampabile() {
      return (
        !R.isNil(this.docAttivo) && StatoInvio.isStampabile(this.docAttivo)
      );
    },
    messaggi() {
      return R.isNil(this.docAttivo)
        ? undefined
        : getUltimiMessaggi(this.docAttivo);
    },
    elencoMessaggi() {
      return R.isNil(this.docAttivo)
        ? undefined
        : getElencoMessaggi(this.docAttivo);
    },
  },
  methods: {
    ...mapActions({
      resetDocAttivo: "fatture/resetDocAttivo",
      resetDoc: "elaboranda/resetDoc",
    }),
    getBannerKey() {
      return window.btoa(
        `${this.docAttivo?.id ?? "new"}${this.statoInvioDoc}_banner`
      );
    },
    updateDocFormKey() {
      this.docFormKey = `${
        this.docAttivo ? this.docAttivo.id : "new"
      }_form_${nanoid()}`;
    },
    resetFattura() {
      if (!this.isNuovoDocAbilitato) return;
      // this.$log.debug('DettaglioDocumento - reset fattura')
      this.resetDocAttivo();
      this.resetDoc();
      this.updateDocFormKey();
      if (this.$route.params.idFattura !== undefined) {
        this.$router.push({ params: { idFattura: null } });
      }
    },
    async scaricaXML() {
      this.toggleLoader(true);
      this.requestingDownload = true;
      try {
        const res = await this.$api.xmlFattura(this.docAttivo.id);
        if (res.status !== 200) {
          throw Error(
            res.status === 404 ? "Fattura non trovata" : "Errore server"
          );
        }
        downloadFile(`${this.docAttivo.id}.xml`, res);
      } catch (e) {
        this.showError({
          title: "Scaricamento xml non riuscito",
          message: e.message,
        });
      }
      this.toggleLoader(false);
      this.requestingDownload = false;
    },
    stampaDoc() {
      // this.$log.info('stampo doc', this.docAttivo.id)

      const base =
        process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_TEST_URL
          : process.env.VUE_APP_BASE_URL;

      const url = `${base}/stampa/doc/${this.datiUtente.codiceFiscale}/${this.docAttivo.id}.pdf`;
      window.open(url, "_blank");
    },
  },
  created() {
    // this.$log.group('DettaglioDocumentoSpSa')
    this.updateDocFormKey();
    // if (this.idFattura === undefined) {
    //   console.log("🚀 ~ created ~ this.resetFattura:", this.resetFattura);
    //   this.resetFattura();
    // }
  },
  beforeDestroy() {
    // this.$log.endGroup('DettaglioDocumentoSpSa')
  },
  notifications: {
    showError: {
      title: "Errore",
      message: "Il server ha restituito un errore",
      type: "error",
      html: null,
    },
  },
};
</script>

<style
  src="@/styles/custom/views/_dettaglio_documento.scss"
  lang="scss"
></style>

